import React from "react";
import { List } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CampaignIcon from "@mui/icons-material/Campaign";
import { MainMenuProps } from "./MainMenu.types";
import MenuItem from "src/components/molucules/MenuItem/MenuItem";

const menu = [
    {
        text: "Campagnes",
        href: "/campaigns",
        icon: <CampaignIcon />,
    },
    {
        text: "Templates",
        href: "/templates",
        icon: <ChatIcon />,
    },
];

export const Layout = ({ open }: MainMenuProps) => {
    return (
        <List>
            {menu.map((item) => (
                <MenuItem
                    href={item.href}
                    hideText={open}
                    icon={item.icon}
                    text={item.text}
                    key={item.text}
                />
            ))}
        </List>
    );
};

export default Layout;
