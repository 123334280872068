import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { KeycloakProvider } from "./providers/KeycloakProvider";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <KeycloakProvider>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </KeycloakProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
